'use strict'

# Declare app level module which depends on filters, and services
App = angular.module('app', [

  'ngResource'
  'ngRoute'
  'app.controllers'
  'app.directives'
  'app.filters'
  'app.services'
  'angulartics'
  'angulartics.google.analytics'

])

App.config([
  '$routeProvider'
  '$locationProvider'
($routeProvider, $locationProvider) ->
  ###
  try 
    Cocoon.Utils.setWebGLEnabled(true);
  catch 
    console.log "setWebGLEnabled error"
  ###
  $routeProvider
    .when('/', {templateUrl: 'views/home.html',controller:"Home"})
        .when('/zahnarzt', {templateUrl: 'views/zahnarzt.html',controller:"Static"})
        .when('/kieferorthopaedin', {templateUrl: 'views/kieferorthopaedin.html',controller:"Static"})
        .when('/praxis', {templateUrl: 'views/praxis.html',controller:"Static"})
        .when('/team', {templateUrl: 'views/team.html',controller:"Static"})
        .when('/imprint', {templateUrl: 'views/imprint.html',controller:"Static"})
        .when('/privacy', {templateUrl: 'views/privacy.html',controller:"Static"})

        .when('/mobile.html', {templateUrl: 'views/mobile.html',controller:"Mobile"})

    # Catch all
    .otherwise({redirectTo: '/'})

  # Without server side support html5 must be disabled.
  $locationProvider.html5Mode(true)
])

AppControllers=angular.module('app.controllers', [])



