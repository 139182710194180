AppControllers.controller('Main', [
  '$scope'
  '$location'
  '$routeParams'
  'isMobile'

($scope,$location,$routeParams,isMobile) ->

	$scope.base="http://praxis-flechtenmacher.de" 
	if (isMobile()) && window.location.pathname!="/mobile.html"
    	window.location="/mobile.html"
    	defer.resolve()
    	return

]) 
