'use strict'

### Directives ###

# register the module with Angular
angular.module('app.directives', [
  # require the 'app.service' module
  'app.services'
])

.directive('appVersion', [
  'version'

(version) ->

  (scope, elm, attrs) ->
    elm.text(version)
])
.directive("goclick", ["$location",($location) ->
  (scope, element, attrs) ->
    path = undefined
    attrs.$observe "goclick", (val) ->
      path = val
      
    element.bind "touchstart", ->

      scope.$apply ->
        $location.path path

])

.directive("gocity", ["$location",($location) ->
  (scope, element, attrs) ->
    path = undefined
    attrs.$observe "gocity", (val) ->
      path = val
      
    element.bind "touchstart", ->
      $(".city."+path).addClass("animate")
      setTimeout(()->
        scope.$apply ->
          $location.path '/cube/'+path
      ,400)


])

.directive("gohome", ["$window",( $window) ->
  (scope, element, attrs) ->
    path = undefined
    attrs.$observe "gohome", (val) ->
      path = val
    
    element.bind "touchstart", ->
      scope.$apply ->
        #$(".cube").show()
        $window.history.back();
        #$location.path path
  ###
   element.bind "click", ->
      scope.$apply ->
        $location.path path        
  ###
])
.directive("openurl", ["$window",( $window) ->
  (scope, element, attrs) ->
    path = undefined
    attrs.$observe "openurl", (val) ->
      path = val
    
    element.bind "touchstart", ->
      scope.$apply ->
        window.open(path, '_system');
        #Cocoon.App.openURL(path);

])

