AppControllers.controller('Mobile', [
  '$scope'
  '$location'
  '$routeParams'
  '$anchorScroll'

($scope,$location,$routeParams,$anchorScroll) ->


    setTimeout(()->
        console.log $(".header").width()+"px"
        $(".slider,#slider,.slides").css({width:$(".header").width()+"px",height:360/874*$(".header").width()+"px"})

        _SlideshowTransitions = [{ $Duration: 1200, $Opacity: 2 } ];
        options = 
            $AutoPlay: true
            $AutoPlaySteps: 1
            $AutoPlayInterval: 5000
            $PauseOnHover: 1
            $ArrowKeyNavigation: true
            $SlideDuration: 500
            $MinDragOffsetToSlide: 20
            $SlideSpacing: 0
            $DisplayPieces: 1
            $ParkingPosition: 0
            $UISearchMode: 1
            $PlayOrientation: 1
            $DragOrientation: 0
            $SlideshowOptions:
                $Class: $JssorSlideshowRunner$
                $Transitions: _SlideshowTransitions
                $TransitionsOrder: 1
                $ShowLink: true
            $BulletNavigatorOptions:
                $Class: $JssorBulletNavigator$
                $ChanceToShow: 2
                $AutoCenter: 1
                $Steps: 1
                $Lanes: 1
                $SpacingX: 10
                $SpacingY: 10
                $Orientation: 1
            $ArrowNavigatorOptions:
                $Class: $JssorArrowNavigator$
                $ChanceToShow: 2
                $Steps: 1
                
        jssor_slider1 = new $JssorSlider$('slider', options)       
        $anchorScroll()
    ,1)
 


]) 

