'use strict'

### Filters ###

angular.module('app.filters', [])

.filter('interpolate', [
  'version',

(version) ->
  (text) ->
    String(text).replace(/\%VERSION\%/mg, version)
])
.filter 'range', () ->
  (input, total) ->
    return [0..total]
.filter 'escape', () ->
	return window.encodeURIComponent
.filter 'trim', () ->
	return (text) ->
		text.trim().replace(/^\s+|\s+$/g, '');
.filter 'phone', () ->
	return (text) ->
		text.trim().replace('+', '').replace(/\s/g, "")
.filter 'http', () ->
	return (text) ->
		if text.indexOf("http")==0
			return text
		else
			return "http://"+text	

		

