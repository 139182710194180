'use strict'

App.factory('isMobile', ()->
  return ()->
    if  navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)
      return true
    
    if navigator.userAgent.match(/Android/i)
      ratio = if window.devicePixelRatio? then window.devicePixelRatio else 1
      if Math.max(screen.width,screen.height)/ratio <450
        return true
    return false     
);


### Filters ###


