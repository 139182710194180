AppControllers.controller('Static', [
  '$scope'
  '$location'
  '$routeParams'
  '$anchorScroll'

($scope,$location,$routeParams,$anchorScroll) ->

	setTimeout(()->
		$anchorScroll()
	,1)
	$scope.scrollToTop= () ->
		window.scrollTo(0,0)

	$scope.optOut= () ->
		gaID = 'UA-69605549-1';
		tmp = 'ga-disable-' + gaID;	
		document.cookie = tmp + '=1; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
		window[gaID] = true;
		alert("Google Analytics wurde deaktiviert")

    
]) 

